import React from "react";
import { useTranslation } from "react-i18next";
import { MoleculesIcon } from "./Molecules";
import TwitterIcon from "../svgs/TwitterIcon.png"; // Updated import for PNG
import { ReactComponent as MailIcon } from "../svgs/MailIcon.svg";
import { ReactComponent as PhoneIcon } from "../svgs/PhoneIcon.svg";
import { ReactComponent as FooterLogo } from "../svgs/FooterLogo.svg";

function Footer() {
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <section className="footer-section page-divider">
      {/* <div className="footerBG"></div>
      <div className="footerbgoverlay"></div> */}
      <div className="bg-to-bottom"></div>

      <div className="footer-wrapper container-1440">
        <div id="footer" className="footer">
          <div className="footer-top">
            <div className="footer-top-left">
              <p className="p-title">
                {/* :تابعنا */}
                {t("follow_us")}
              </p>
              <div className="footer-company-dedatils">
                <a
                  href="https://twitter.com/FutureHarvestSA"
                  target="_blank"
                  rel="noreferrer"
                >
                  <MoleculesIcon
                    svgIconClass="twitter-icon"
                    svgIcon={<img src={TwitterIcon} alt="Twitter Icon" />} // Updated to use PNG
                    paragraph="@futureharvestsa"
                  />
                </a>
              </div>
            </div>
            <div className="footer-top-right">
              <p className="p-title">
                {/* :للاستفسارات الرجاء التواصل معنا عن طريق */}
                {t("for_inquiry")}
              </p>
              <div className="footer-company-dedatils">
                <a href="mailto:info@futureharvest.sa">
                  <MoleculesIcon
                    svgIconClass="mail-icon"
                    svgIcon={<MailIcon />}
                    paragraph="info@futureharvest.sa"
                  />
                </a>
                <MoleculesIcon
                  svgIconClass="phone-icon"
                  svgIcon={<PhoneIcon />}
                  paragraph="920017140"
                />
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <FooterLogo />
            <p>
              Future Harvest for food industries &#169; {year}. All rights reserved
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
