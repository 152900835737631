import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { MoleculesBltPoint } from "./Molecules";
import { LeftLink } from "./Molecules";

function Product() {
  const { t } = useTranslation();

  return (
    <section className="product-section page-divider">
      <div className="product-wrapper container-1440">
        <div className="product section-padding section-background">
          {/* text-right class was on product element hehe */}
          <div
            className="test-for-side-change"
            style={{ position: "relative" }}
          >
            <LeftLink link="/products" />
            <h1 className="section-heading">
              {/* المنتجات */}
              {t("products")}
            </h1>
            <p>
              {/* :التجفيف بتجميد المواد الغذائية */}
              {t("para_of_products")}
            </p>
            <div className="product-tags">
              {/* <MoleculesBltPoint name="لقطاع البحث" /> */}
              <MoleculesBltPoint name={t("research_sector")} />
              {/* <MoleculesBltPoint name="لقطاع المستهلكين" /> */}
              <MoleculesBltPoint name={t("consumer_sector")} />
              {/* <MoleculesBltPoint name="لقطاع الاعمال" /> */}
              <MoleculesBltPoint name={t("business_sector")} />
            </div>
            <div className="product-images">
              <div className="product-left-image scale-image-on-hover">
                <Link to={`/products`}>
                  <div className="product-left-image-img"></div>
                </Link>
              </div>
              <div className="product-right-image scale-image-on-hover">
                <Link to={`/products`}>
                  <div className="product-right-image-img"></div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div id="joinus"></div>
      </div>
    </section>
  );
}

export default Product;
