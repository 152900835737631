import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "./components/Footer";

function ResearchAndDevelopment() {
  const { t } = useTranslation();
  return (
    <>
      <section className="research-and-development-page-section page-starting-padding">
        <div className="bg-to-top"></div>
        <div className="research-and-development-wrapper container-1440">
          <div
            id="research-and-development"
            className="research-and-development"
          >
            <h2 id="research&development" className="title1">
              {t("research_and_development")}
            </h2>

            <p className="paragraph-about p16-32">{t("research_para1")}</p>

            <div className="resources-development-img"></div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ResearchAndDevelopment;
