import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "./components/Footer";
import Form from "./components/Form";
import MapAddress from "./components/MapAddress";
import { foodHarvestForFoodIndustriesUrl } from "./components/MapAddress";
import { sudairIndustrialAndBusinessCityUrl } from "./components/MapAddress";

function ContactPage() {
  const { t } = useTranslation();
  return (
    <>
      <section className="contact-page-section page-starting-padding">
        <div className="bg-to-top"></div>
        <div className="contact-page-wrapper container-1440">
          <h1 className="section-heading mb-24">
            {t("connect_with_us")}
          </h1>
          <div className="contact-page-contact">
            <Form />
            <MapAddress
              heading={t("office_location")}
              paragraph={t("office_address")}
              url={foodHarvestForFoodIndustriesUrl}
              title="Future Harvest for Food Industries Map Address"
            />
            <MapAddress
              heading={t("factory_location")}
              paragraph={t("factory_address")}
              url={sudairIndustrialAndBusinessCityUrl}
              title="Sudair Industrial And Business City Map Address"
            />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ContactPage;
