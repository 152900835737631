import React from "react";
import Showcase from "./components/Showcase";
import Product from "./components/Product";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function Home({themetw}) {
  return (
    <main className="home" id="home">
      <Showcase />
      <Product />
      <Contact themetw={themetw}/>
      <Footer />
    </main>
  );
}

export default Home;
