import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./website/Home";
import ProductPage from "./website/ProductPage";
import AboutPage from "./website/AboutPage";
import ResearchAndDevelopment from "./website/ResearchAndDevelopment";
import ContactPage from "./website/ContactPage";
import "./styleWebsite/main.scss";
import Header from "./website/components/Header";
import ScrollToTopPage from "./website/components/ScrollToTopPage";

function App() {
  const [theme, setTheme] = useState(true);

  return (
    <>
      {/* made the header global so you don't have to call 
    the header on each page everytime you add a page */}

      <Header
        twittertheme={(a) => {
          setTheme(a);
        }}
      />
      <ScrollToTopPage />
      <Routes>
        <Route exact path="/" element={<Home themetw={theme} />} />
        <Route exact path="/ar" element={<Home themetw={theme} />} />
        <Route exact path="/en" element={<Home themetw={theme} />} />
        <Route path="/products" element={<ProductPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage themetw={theme} />} />
        <Route
          path="research-and-development"
          element={<ResearchAndDevelopment />}
        />
      </Routes>
    </>
  );
}

export default App;
