import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Showcase() {
  const { t } = useTranslation();

  return (
    <section className="showcase-section">
      {/* <span className="showcase-bg-atmosphere-logo-1"></span>
      <span className="showcase-bg-atmosphere-logo-1 overlay"></span> */}
      <div className="bg-to-top"></div>
      <span className="showcase-bg-atmosphere-logo-2"></span>
      <div className="showcase-wrapper">
        <div className="showcase container-1440">
          <div className="showcase-image">
            <div className="showcase-image-img"></div>
          </div>
          <div className="showcase-content">
            {/* <h1 className="big-heading">شركة قطاف المستقبل لصناعات الغذائية</h1> */}
            <h1 className={"big-heading"}> {t("showcase_heading")} </h1>
            {/* <p>
              هي شركة سعودية باستثمارات تصل إلى 300 مليون ريال سعودي ضمن خطة
              خمسية وهي أول شركة في الشرق الأوسط وشمال أفريقيا تستخدم تقنية
              التجفيف بتجميد أو كما تعرف بتجفيد
            </p> */}
            <p> {t("showcase_para1")} </p>
            <div className="hero-image-small">
              <div className="hero-image-small-img"></div>
            </div>
            {/* <p>
              شركة قطاف المستقبل لصناعات الغذائية تعتبر المركز الصناعي والبحثي
              في مجال التجفيف بتجميد في المنطقة وهي إضافة لدعم سلاسل الامداد في
              المملكة والمنطقة
            </p> */}
            <p> {t("showcase_para2")} </p>
            <NavLink to={"/about"} className="test">
              {/* عرض المزيد */}
              {t("show_more")}
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Showcase;
