import React, { useState, useRef, useEffect } from "react";
import NavbarList from "./NavbarList";

function SideMenu() {
  const [open, setOpen] = useState(false);
  const openSideMenu = () => {
    setOpen((open) => !open);
  };
  let isOpen = open ? "open" : "";

  /**
   * Hook for clicks outside of the passed ref
   */
  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (!buttonRef.current.contains(e.target)) {
        setOpen(false);
      }
    });
  }, []);
  const buttonRef = useRef(null);

  return (
    <div className="navigation">
      <div className="sidemenu-wrapper">
        <button
          className={`hamburger-icon ${isOpen}`}
          onClick={openSideMenu}
          ref={buttonRef}
        >
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
        </button>
      </div>
      <div className={`navigation__background ${isOpen}`}>
        <div className="side-navbar-wrapper">
          <div className="small-nav-wrapper">
            <NavbarList />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideMenu;
