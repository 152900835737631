import { React, useEffect } from "react";
import i18n from "i18next";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import "/node_modules/flag-icons/css/flag-icons.min.css";
// import { ReactComponent as Globe } from "../svgs/Globe.svg";
const languages = [
  {
    code: "ar",
    name: "عربي",
    country_code: "sa",
    dir: "ltr", // this is a mess i hade to reserve them
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
    dir: "rtl",
  },
];
export { languages };

function LanguageMenu() {
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "ar";
  // const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  useEffect(() => {
    document.title = t("app_title");
    if (currentLanguageCode === "en") {
      document.body.classList.add("en");
    } else {
      document.body.classList.remove("en");
    }
  }, [currentLanguageCode, t]);
  // const setLanguage = currentLanguageCode === "en" ? "ar" : "en";
  return (
    // <button className="btn btn-link theme-tag svg-stroke"
    // onClick={() => i18n.changeLanguage(setLanguage)}>
    //   <Globe className="globe-icon"/>
    // </button>
    <div>
      {languages.map(({ code, name, country_code }) => (
        <button
          key={code}
          // className="language-option theme-tag"
          className={
            currentLanguageCode === code
              ? "hide language-option theme-tag"
              : "language-option theme-tag"
          }
          onClick={() => i18n.changeLanguage(code)}
          style={{
            position: currentLanguageCode === "ar" && "relative",
            bottom: currentLanguageCode === "ar" && "-2px",
          }}
        >
          {name}
        </button>
      ))}
    </div>
  );
}

export default LanguageMenu;

// let dynamicLanguageOptions = (
//   <div className="language-option">
//       {languages.map(({ code, name, country_code }) => (
//         <button
//           key={code}
//           className="language-option theme-tag"
//           onClick={() => i18n.changeLanguage(code)}
//         >
//           {name}
//         </button>
//       ))}
//     </div>
// )
