import React from "react";
import { useTranslation } from "react-i18next";
import { Timeline } from "react-twitter-widgets";

function TwitterTimeline({ themetw, lang }) {
  const { t } = useTranslation();
  
  return (
    <>
      <h2 className="section-heading-h2 mb-20 mt-34">
        {/* : أخبار */}
        {t("news2")}
      </h2>
      <section className="twitter-container">
        <div className="twitter-embed">
          <Timeline
            dataSource={{
              sourceType: "profile",
              screenName: "FutureHarvestSA",
            }}
            options={{
              lang: lang,
              height: "800",
              theme: themetw,
            }}
          />
        </div>
      </section>
    </>
  );
}

export default TwitterTimeline;
