import { React, useState } from "react";
/*import { NavLink } from "react-router-dom";*/
import { useTranslation } from "react-i18next";
import { ReactComponent as LinkIcon } from "../svgs/LinkIcon.svg";

function MoleculesBltPoint(props) {
  return (
    <div className="molecule-blt-pnt-component molecule-wrapper">
      <p className="molecule-p">{props.name}</p>
      <div className="molecute-point"></div>
    </div>
  );
}
export { MoleculesBltPoint };

function MoleculesIcon(props) {
  const linkClassClicked = "primary-hover-styles-clicked";
  const linkClassHover = "primary-hover-styles-hover";
  const [myClass, addClass] = useState("");
  const addStyles = () => {
    addClass(linkClassHover);
  };
  const addClickedStyles = (e) => {
    addClass(linkClassClicked);
  };
  return (
    <div
      className={`molecule-icon-component molecule-wrapper primary-hover-styles ${myClass}`}
      onMouseMove={addStyles}
      onClick={addClickedStyles}
      onMouseLeave={() => addClass("")}
    >
      <div className={`molecute-svg ${props.svgIconClass}`}>
        {props.svgIcon}
      </div>
      <p className="molecule-p">{props.paragraph}</p>
    </div>
  );
}

export { MoleculesIcon };

function LeftLink(props) {
  const { t } = useTranslation();
  return (
    <div className="section-link">
      <a
        href="https://easybites.com/"
        className="section-link-atag"
        target="_blank"
        rel="noopener noreferrer"
      >
        <LinkIcon className="section-link-icon" />
        {/* عرض المزيد */}
        {t("show_more")}
      </a>
    </div>
  );
}

export { LeftLink };
