import React from "react";

export let foodHarvestForFoodIndustriesUrl =
  "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28970.91962705246!2d46.687706!3d24.81719!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6afceadea8f769dc!2zRnV0dXJlIEhhcnZlc3QgZm9yIEZvb2QgSW5kdXN0cmllcyDYtNix2YPYqSDZgti32KfZgSDYp9mE2YXYs9iq2YLYqNmEINmE2LXZhtin2LnYp9iqINin2YTYutiw2KfYptmK2Kk!5e0!3m2!1sen!2sus!4v1667050900607!5m2!1sen!2sus";

  export let sudairIndustrialAndBusinessCityUrl =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115026.82753649572!2d45.686562849999994!3d25.7180498!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2b0490ba595c99%3A0x1f2e3b1aefcc52ad!2sSudair%20Industrial%20And%20Business%20City%20Saudi%20Arabia!5e0!3m2!1sen!2s!4v1667051784424!5m2!1sen!2s";

function MapAddress(props) {
  return (
    <section className="map-address-wrapper mt-60">
      <h1 className="contact-page-heading">{props.heading}</h1>
      <p className="mt-16 mb-24">{props.paragraph}</p>
      <div className="map">
        <iframe
          src={props.url}
          width="600"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title={props.title}
        ></iframe>
      </div>
    </section>
  );
}

export default MapAddress;
