import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "./components/Footer";
import { MoleculesBltPoint } from "./components/Molecules";

function AboutPage() {
  const { t } = useTranslation();
  return (
    <>
      <section className="about-page-section page-starting-padding">
        <div className="bg-to-top"></div>
        <div className="about-wrapper container-1440">
          <div id="about" className="about">
            <div className="new-first-content-wrapper">
              <div className="new-left-logo-about"></div>
              <div className="new-right-para-content">
                <h1 className="title1">{t("about_company_heading")}</h1>
                <p className="paragraph-about">{t("about_company_para")}</p>

                <div className="about-bg-small"></div>

                <h2 className="title2">
                  {t("food_category")}
                </h2>

                <div className="blt-point-container">
                  <MoleculesBltPoint name={t("research_sector")} />
                  <MoleculesBltPoint name={t("consumer_sector")} />
                  <MoleculesBltPoint name={t("business_sector")} />
                </div>

                <p className="paragraph-about p24-32">{t("about_para1")}</p>

                <p className="paragraph-about p24-32">{t("about_para2")}</p>
              </div>
            </div>

            <div className="second-content-wrapper">
              <div className="img-strawberry mb-32"></div>
              <p className="paragraph-about">{t("about_para3")}</p>
              <div className="img-beans mt-32 mb-32"></div>
            </div>

            <p className="paragraph-about p24-32">{t("about_para4")}</p>

            <h2 className="title2">{t("vision")}</h2>

            <p className="paragraph-about p16-32">{t("vision_para")}</p>

            <h2 className="title2">{t("mission")}</h2>

            <p className="paragraph-about p16-32">{t("mission_para")}</p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default AboutPage;
