import React from "react";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

function NavbarList() {
  const { t } = useTranslation();
  const myLinks = [
    {
      id: 1,
      name: t("overview"),
      link: "/",
    },
    {
      id: 2,
      name: t("about"),
      link: "/about",
    },
    {
      id: 3,
      name: t("research_and_development"),
      link: "/research-and-development",
    },
    {
      id: 4,
      name: t("products"),
      link: "/products",
    },
    {
      id: 6,
      name: t("contact_us"),
      link: "/contact",
    },
  ];

  const myHashLinks = [
    {
      id: 5,
      name: t("join_us"),
      link: "/#joinus",
    },
  ];
  return (
    <div className="navbar-list">
      <ul>
        {myLinks.map((item) => (
          <li key={item.id}>
            <NavLink
              end
              to={item.link}
              className={({ isActive }) =>
                isActive
                  ? "nav-links nav-active"
                  : "nav-links hover-primary nav-inactive"
              }
            >
              {item.name}
            </NavLink>
          </li>
        ))}
        {myHashLinks.map((hashItem) => (
          <li key={hashItem.id}>
            <HashLink
              to={hashItem.link}
              className="right hover-primary nav-links"
            >
              {hashItem.name}
            </HashLink>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default NavbarList;
