import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as FHLogo } from "../svgs/NavbarLogo.svg";
import { ReactComponent as Moon } from "../svgs/MoonIcon.svg";
import { ReactComponent as Sun } from "../svgs/SunIcon.svg";
import LanguageMenu from "./LanguageMenu";
import SideMenu from "./SideMenu";
import NavbarList from "./NavbarList";

const Header = ({ twittertheme }) => {
  //==========================================================//
  // hook to switch between dark & light theme
  // by saving the theme nametag globally so
  // it can be used after refreshing the page
  //==========================================================//

  useEffect(() => {
    const data = window.localStorage.getItem("ThemeSetting");

    // if (data !== null) setTheme(data); // on dark them when page refresh twiter goes back to light on dark them
    if (data !== null) {
      setTheme(data);
      twittertheme(data); // We insert this line here to address it.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // CAUTION: Keep the dependency array in place and don't add twitterThem to it.

  const [theme, setTheme] = useState("light");
  const switchTheme = () => {
    if (theme === "light") {
      setTheme("dark");
      twittertheme("dark");
    } else {
      setTheme("light");
      twittertheme("light");
    }
  };

  useEffect(() => {
    window.localStorage.setItem("ThemeSetting", theme);
    if (theme === "light") {
      // had to make few changes to adjust my "en" class :)
      document.body.classList.add("light");
    } else {
      document.body.classList.remove("light");
    }
    // document.body.className = theme;
  }, [theme]);

  //==========================================================//

  return (
    <div className="side-footer-grand-wrapper">
      <section className="header-section">
        <header>
          <SideMenu className="hidden-menu" />
          <nav className="big">
            <div className="appearance flex">
              <button
                href="#"
                className="btn btn-link theme-tag"
                onClick={() => switchTheme()}
              >
                {theme === "light" ? (
                  <Sun className="icon moon-icon svg-stroke" />
                ) : (
                  <Moon className="icon sun-icon" />
                )}
              </button>
              <LanguageMenu />
            </div>
            <div className="nav-links-container">
              <NavbarList />
              <NavLink to={"/"}>
                <FHLogo className="icon f-h-logo-icon" />
              </NavLink>
            </div>
          </nav>
          <div className="small-nav">
            <div className="small-nav-inner">
              <LanguageMenu />
              <button
                className="btn btn-link theme-tag"
                onClick={() => switchTheme()}
              >
                {theme === "dark" ? (
                  <Moon className="icon moon-icon" />
                ) : (
                  <Sun className="icon sun-icon svg-stroke" />
                )}
              </button>
              <NavLink to={"/"}>
                <FHLogo className="small-nav-logo-abs" />
              </NavLink>
            </div>
          </div>
        </header>
      </section>
    </div>
  );
};
export default Header;
