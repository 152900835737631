import React from "react";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import Footer from "./components/Footer";
import { ReactComponent as CardLogo } from "../website/svgs/card-logo.svg";

function ProductPage() {
  const { t } = useTranslation();
  return (
    <>
      <section className="product-page-section page-starting-padding">
        <div className="bg-to-top"></div>
        <div className="product-page-wrapper container-1440">
          <div className="product-page">
            <div className="product-card-wrapper">
              <div className="card research-card">
                <div className="logo-title-card">
                  <CardLogo className="card-logo" />
                  <span className="card-title">
                    {t("research_sector_card")}
                  </span>
                </div>

                <p className="card-title2">
                  {t("products_category")}
                </p>

                {/* <!-- Card Description Points --> */}
                <div className="card-description-container">
                  <span className="product-title">
                    {t("all_types_food")}
                  </span>
                  <div className="description-bulletpoint"></div>
                </div>

                {/* <!-- ------------------- --> */}

                <div className="product-card-images research-img"></div>

                <HashLink to="/#contact">
                  <span className="primary-btn-fw primary-btn">
                    {t("connect_with_us_card")}
                  </span>
                </HashLink>
              </div>

              {/* <!-- Consumer Card --> */}
              <div className="card consumer-card">
                <div className="logo-title-card">
                  <CardLogo className="card-logo" />
                  <span className="card-title">
                    {t("consumer_sector_card")}
                  </span>
                </div>

                <p className="card-title2">
                  {t("products_category")}
                </p>

                {/* <!-- Card Description Points --> */}
                <div className="product-list-line1">
                  <div className="card-description-container">
                    <span className="product-title">
                      {t("vegetables_chips")}
                    </span>
                    <div className="description-bulletpoint"></div>
                  </div>

                  <div className="card-description-container">
                    <span className="product-title">
                      {t("fruits_chips")}
                    </span>
                    <div className="description-bulletpoint"></div>
                  </div>
                </div>

                <div className="card-description-container">
                  <span className="product-title">
                    {t("coffee")}
                  </span>
                  <div className="description-bulletpoint"></div>
                </div>

                {/* <!-- ------------------- --> */}

                <div className="product-cards consumer-sector">
                  <div className="product-flex-row flex-row1">
                    <div className="product-items item1"></div>
                    <div className="product-items item2"></div>
                  </div>

                  <div className="product-flex-row flex-row2">
                    <div className="product-items item3"></div>
                    <div className="product-items item4"></div>
                  </div>
                </div>

                <HashLink to="/#contact">
                  <span className="primary-btn-fw primary-btn">
                    {t("connect_with_us_card")}
                  </span>
                </HashLink>
              </div>

              {/* <!-- Business Card --> */}
              <div className="card business-card">
                <div className="logo-title-card">
                  <CardLogo className="card-logo" />
                  <span className="card-title">
                    {t("business_sector_card")}
                  </span>
                </div>

                <p className="card-title2">
                  {t("products_category")}
                </p>

                {/* <!-- Card Description Points --> */}
                <div className="product-list-line1">
                  <div className="card-description-container">
                    <span className="product-title">
                      {t("vegetables")}
                    </span>
                    <div className="description-bulletpoint"></div>
                  </div>

                  <div className="card-description-container">
                    <span className="product-title">
                      {t("fruits")}
                    </span>
                    <div className="description-bulletpoint"></div>
                  </div>

                  <div className="card-description-container">
                    <span className="product-title">
                      {t("liquid_coffee")}
                    </span>
                    <div className="description-bulletpoint"></div>
                  </div>

                  <div className="card-description-container">
                    <span className="product-title">
                      {t("meat")}
                    </span>
                    <div className="description-bulletpoint"></div>
                  </div>
                </div>

                <div className="card-description-container">
                  <span className="product-title">
                    {t("meals")}
                  </span>
                  <div className="description-bulletpoint"></div>
                </div>

                {/* <!-- ------------------- --> */}

                <div className="product-cards business-sector">
                  <div className="product-flex-row flex-row1">
                    <div className="product-items item1"></div>
                    <div className="product-items item2"></div>
                  </div>

                  <div className="product-flex-row flex-row2">
                    <div className="product-items item3"></div>
                    <div className="product-items item4"></div>
                  </div>
                </div>

                <HashLink to="/#contact">
                  <span className="primary-btn-fw primary-btn">
                    {t("connect_with_us_card")}
                  </span>
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ProductPage;
