import React from "react";
import { useTranslation } from "react-i18next";

function Form() {
  const { t } = useTranslation();
  return (
    <form
      className="form"
      action="https://formsubmit.co/info@futureharvest.sa"
      method="POST"
      target="_blank" // like this
      autoComplete="off"
    >
      {/* <input
                  type="hidden"
                  name="_next"
                  value="http://localhost:3000/#formSucces"
                ></input> */}
      <div className="flex-display-for-form-inner">
        <div className="form-row">
          <label htmlFor="name">
            {/* الاسم */}
            {t("name")}
          </label>
          <input
            type="text"
            name="name"
            placeholder={t("write_your_name")}
          ></input>
        </div>
        <div className="form-row">
          <label htmlFor="email">
            {/* البريد الالكتروني */}
            {t("email")}
          </label>
          <input
            type="text"
            name="email"
            placeholder={t("write_your_email")}
          ></input>
        </div>
      </div>
      <div className="form-row">
        <label htmlFor="message" name="message">
          {/* الرسالة */}
          {t("message")}
        </label>
        <textarea
          name="message"
          placeholder={t("write_your_message")}
        ></textarea>
      </div>
      <button className="primary-btn-fw">
        {/* ارسال */}
        {t("send")}
      </button>
    </form>
  );
}

export default Form;
