import React from "react";
import { useTranslation } from "react-i18next";
import JoinUs from "./JoinUs";
import Form from "./Form";
import TwitterTimeline from "./TwitterTimeline";

function Contact({ themetw }) {
  const { t } = useTranslation();

  return (
    <section className="contact-section page-divider">
      <div className="contact-wrapper container-1440">
        <div className="contact section-padding">
          <div className="contact-background-shade"></div>
          <JoinUs />
          <h2 className="section-heading-h2 mt-32 mb-24">
            {/* :تواصل معنا */}
            {t("connect_with_us")}
          </h2>
          {/* Form */}
          <Form />
          <TwitterTimeline lang={t("lang")} themetw={themetw} />
        </div>
      </div>
    </section>
  );
}

export default Contact;
