import React from "react";
import { useTranslation } from "react-i18next";
import { MoleculesIcon } from "./Molecules";
import { ReactComponent as MailIcon } from "../svgs/MailIcon.svg";

function JoinUs() {
  const { t } = useTranslation();
  return (
    <section id="join-us" className="join-us-section">
      <div className="join-us">
        <h2 className="section-heading-h2">
          {/* :انضم إلينا */}
          {t("join_us")}
        </h2>
        <div id="contact"></div>
        {/* <p className="join-us-paragraph">
          اذا اردت أن تنضم إلى عائلتنا لتلتحق بالنجاح الذي نحققه الرجاء التواصل
          معنا من خلال البريد
        </p> */}
        <p className="join-us-paragraph">
          {t("join_us_para")}
        </p>
        <a href="mailto:recruitment@futureharvest.sa">
          <MoleculesIcon
            svgIconClass="phone-icon"
            svgIcon={<MailIcon />}
            paragraph="recruitment@futureharvest.sa"
          />
        </a>
      </div>
    </section>
  );
}

export default JoinUs;
